<template>
  <div>
    <top-nav :showLogo="false" title=" "></top-nav>
    <div class="container">
      <div class="logo-cont">
        <img class="logo" src="../../assets/image/bank/binding/logo.png">
      </div>
    </div>
    <div class="bottom-cont">
      <div class="title">
        {{ $t('我的邀请人') }}
      </div>
      <div class="input-cont">
        <input type="text" @focus="promptText =''" v-model="invite_code"
               :placeholder="$t('请输入')">
        <img v-if="invite_code" @click="emptyCode" class="clone-icon"
             src="../../assets/image/rwa/binding/clone-icon.png"/>
      </div>
      <div class="prompt-cont">
        {{ promptText }}
      </div>
      <div class="tips">
        {{ $t('银行提示') }}
      </div>

      <div class="confirm-cont">
        <div class="confirm-but" @click="bindingUser">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <!--          <div class="title">{{ $t('绑定成功') }}</div>-->
          <!--          <div class="text">{{ invite_code }}</div>-->
          <div class="title"></div>
          <div class="text">{{ $t('绑定成功') }}</div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">

        <div class="_but" @click="onShowActivationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {postBindCode, getUserInfo} from "../../api";
import {account} from "../../assets/language/zh_TW";

export default {
  name: "binding",
  components: {TopNav},
  data() {
    return {
      invite_code: '',
      promptText: '',
      showOperationSuccess: false,
      account: null,
      allowance: 0,//已授权数量
      burBalance: 0, //用户bur
    }
  },
  created() {
    // const res = this.$store.dispatch('bur/getBinds', '0xDBC05568462882EA0a6875f104Bb9Ad9629E3447')
    // console.log('res------', res)
    this.init()
    let invite_code = sessionStorage.getItem('invite_code')
    if (invite_code) {
      this.invite_code = invite_code
    }
  },
  methods: {
    async init() {
      await this.getAccount()
      await this.getAllowance()
      await this.getBurBalance()
    },
    async getAccount() {
      await this.$store.dispatch('bur/getAccount')
          .then(res => {
            if (res) {
              this.account = res
            }
          })
    },
    //已获取授权数量
    async getAllowance() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      await this.$store.dispatch('bur/getAllowance', that.account)
          .then(res => {
            console.log('getAllowance----', res);
            that.allowance = res;
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    //授权额度 默认10
    async authorizedTransaction(number) {
      let that = this;
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      this.$store.dispatch('bur/authorizedTransaction',
          {'account': that.account, 'number': number}
      )
          .then(res => {
            console.log("授权成功", res);
            that.getAllowance()
            that.$store.dispatch('bur/getAllowance', that.account)
                .then(res => {
                  console.log('1232222', res);
                  that.allowance = res;
                  if (res >= 10) {
                    setTimeout(() => {
                      that.bindInvitation()
                    }, 300)
                  }
                })
                .finally(() => {
                  that.$toast.clear()
                })
          })
          .catch(err => {
            setTimeout(() => {
              that.$toast(that.$t("操作失败"));
            }, 300)
            console.log(err);
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    async getBurBalance() {
      let that = this;
      await this.$store.dispatch('bur/getBalanceOf', that.account)
          .then(res => {
            if (res) {
              that.burBalance = res
              console.log('bur--------', res)
            }
          })
    },
    //绑定邀请人
    bindInvitation() {
      let that = this;
      that.$toast.loading({
        message: '',
        duration: 0,
      });
      const temp = 0;
      this.$store.dispatch('bur/getBinds', that.invite_code)
          .then(res => {
            if (res === false) {
              setTimeout(() => {
                that.$toast(that.$t("邀请地址错误"));
              }, 300)
            } else {
              this.$store.dispatch('bur/bindInvitation',
                  {'account': that.account, 'invitationCode': that.invite_code}
              )
                .then(res => {
                  console.log(res);
                  that.onShowActivationSuccess()
                })
                .catch(err => {
                  console.log(err);
                  setTimeout(() => {
                    that.$toast(that.$t("操作失败"));
                  }, 300)
                })
                .finally(() => {
                  that.$toast.clear()
                })
            }
          })
    },
    emptyCode() {
      this.invite_code = ''
    },
    bindingUser() {
      let that = this;
      const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
      console.log('addressRegex---',addressRegex.test(this.invite_code));

      if (!addressRegex.test(this.invite_code)) {
        this.promptText = that.$t('请输入正确邀请码')
        return
      }
      console.log('burBalance-------qqqqq', that.burBalance)
      if (that.burBalance < 10) {
        setTimeout(() => {
          that.$toast(that.$t("用户BUR额度不能小于10"));
        }, 300)
        return
      }
      if (that.allowance >= 10) {
        console.log(11111)
        that.bindInvitation()
      } else {
        console.log(22222)
        that.authorizedTransaction('10')
      }
    },
    postInfo() {
      let that = this;
      const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
      console.log(addressRegex.test(this.invite_code));

      if (!addressRegex.test(this.invite_code)) {
        this.promptText = that.$t('请输入正确邀请码')
        return
      }


      // this.promptText = ''
      // this.$toast.loading({
      //   message: '',
      //   duration: 0,
      // });
      // postBindCode({'invite_code': this.invite_code})
      //     .then(({data}) => {
      //       console.log(data);
      //       if (data.code == 200) {
      //         that.onShowActivationSuccess()
      //       } else {
      //         setTimeout(() => {
      //           that.$toast(data.message);
      //         }, 500)
      //       }
      //     })
      //     .finally(() => {
      //       that.$toast.clear()
      //     })
    },
    onShowActivationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.invite_code = ''
        this.$router.push({
          path: '/bank'
        })
      }
    },
  }

}
</script>

<style scoped lang="less">

.container {
  position: relative;
  height: 90vh;
  background: url("../../assets/image/bank/binding/bg.png") no-repeat;
  background-position: top;
  background-size: 100%;
}

.logo-cont {
  position: absolute;
  width: 100%;
  top: 34.5vh;
  text-align: center;

  .logo {
    width: 8.11rem;
  }
}

.bottom-cont {
  position: absolute;
  z-index: 2;
  width: 100%;
  left: 0;
  bottom: 0;

}

.title {
  padding-left: 2.39rem;
  margin-bottom: 0.32rem;
  font-size: 1rem;
  color: #E7BD71;
}

.input-cont {
  width: 90%;
  box-sizing: border-box;
  margin: auto;
  margin-top: 1rem;
  padding: 1rem 1.36rem;
  background: #1B1B1B;
  border-radius: 1.57rem;

  input {
    width: 80%;
    background-color: #1B1B1B;
    border: none;
    color: #fff;
  }

  .clone-icon {
    float: right;
    width: 1.29rem;
  }
}

.tips {
  padding: 0 2.2rem;
  font-size: 0.86rem;
  color: #B5904E;
  line-height: 1.5rem;
}

.confirm-cont {
  margin-top: 8vh;
  padding: 1rem 0.9rem;
  background: #151515;

  .confirm-but {
    text-align: center;
    line-height: 3.14rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.57rem;
    font-size: 1.29rem;
    color: #000000;
  }
}

.prompt-cont {
  width: 85%;
  margin: auto;
  color: #E64937;
  font-size: 0.86rem;
  padding-top: 0.23rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
</style>
